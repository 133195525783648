<template>
  <div class="animated fadeIn">
    <div>
      <b-row>
        <div class="col-12">
          <b-card>
            <b-row class="mx-md-n3 mx-1">

              <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mb-xl-0 mb-3">
                <div v-if="loadingWarehouses">
                  <loading-animation />
                </div>
                <model-list-select
                  v-else
                  :list="warehouses"
                  option-value="id"
                  option-text="name"
                  v-model="selectedWarehouse"
                  placeholder="Selecione o Armazém"
                />
              </div>

              <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mb-xl-0 mb-3">
                <model-list-select
                  :list="operations"
                  option-value="value"
                  option-text="label"
                  v-model="selectedOperation"
                  placeholder="Selecione a Operação"
                />
              </div>

              <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="row">
                  <div class="col">
                    <b-input-group>
                      <b-form-input
                        v-model="filter"
                        @keyup.enter="onFiltering"
                        type="number"
                        placeholder="Procurar por..."
                      />
                      <b-input-group-append>
                        <b-button size="md" text="Button" variant="outline-info" @click.passive="onFiltering">
                          <i class="cui-magnifying-glass"></i>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <div class="col-auto">
                    <div class="row justify-content-end">
                      <div class="col-auto">
                        <button type="button" class="btn btn-outline-info cursor-pointer" @click="changeSortOption" v-b-tooltip.hover
                          :title="'Ordenação - ' + selectedSortOption.label">
                          <i :class="'fa fa-' + selectedSortOption.icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-sm-3 mt-2">
                <div class="row align-items-center">
                  <div class="col-sm-auto col-12 mb-sm-0 mb-1">
                    <label class="mb-0">Item:</label>
                  </div>
                  <div class="col">
                    <div v-if="loadingItems">
                      <loading-animation />
                    </div>
                    <model-list-select
                      v-else
                      :list="items"
                      option-value="id"
                      option-text="label"
                      v-model="selectedItem"
                      placeholder="Digite para pesquisar..."
                      @searchchange="searchItem"
                      @keyup.enter="onFiltering"
                      :filterPredicate="alwaysTrueFilterPredicate"
                    />
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="row align-items-center pt-3">
                  <div class="col-sm-auto col-12 mb-sm-0 mb-1">
                    <label class="mb-0">Status:</label>
                  </div>
                  <div class="col">
                    <div v-if="loadingTicketStatuses">
                      <loading-animation />
                    </div>
                    <model-list-select
                      v-else
                      :list="ticketStatuses"
                      option-value="id"
                      option-text="label"
                      v-model="selectedTicketStatus"
                      placeholder="Selecione o status"
                      @searchchange="searchTicketStatus"
                      @keyup.enter="onFiltering"
                      :filterPredicate="alwaysTrueFilterPredicate"
                    />
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="row align-items-center pt-3">
                  <div class="col-sm-auto col-12 mb-sm-0 mb-1">
                    <label class="mb-0">Rota:</label>
                  </div>
                  <div class="col">
                    <div v-if="loadingRoutes">
                      <loading-animation />
                    </div>
                    <model-list-select
                      v-else
                      :list="routes"
                      option-value="id"
                      option-text="name"
                      v-model="selectedRoute"
                      placeholder="Selecione a rota"
                      @searchchange="searchRoute"
                      @keyup.enter="onFiltering"
                      :filterPredicate="alwaysTrueFilterPredicate"
                    />
                  </div>
                </div>
              </div>

            </b-row>
          </b-card>
        </div>
      </b-row>

      <b-row>
        <div class="col-12">
          <b-tabs
            lazy
          >
            <b-tab :active="activeTabIndex == 0" @click="tabChanged" class="px-md-3 px-0">
              <template slot="title">
                <p>Saídas Pendentes</p>
              </template>

              <OrderTable
                id="released"
                urlStart="/orders/item-out/start/"
                resourceName="orderResourceList"
                :url="endpoints.findAll"
                role="PEN"
                movementType="SAI"
                ref="releasedTable"
                :enableCancel="isUserManager"
              >

              </OrderTable>
            </b-tab>
            <b-tab :active="activeTabIndex == 1" @click="tabChanged" class="px-md-3 px-0">
              <template slot="title">
                <p>Saídas Finalizadas</p>
              </template>
              <OrderTable
                id="complete"
                urlStart="/orders/item-out/start/"
                resourceName="orderResourceList"
                :url="endpoints.findAll"
                role="FIN"
                movementType="SAI"
                ref="completeTable"
                :enableCancel="isUserManager"
              />
            </b-tab>
            <b-tab :active="activeTabIndex == 2" @click="tabChanged" class="px-md-3 px-0">
              <template slot="title">
                <p>Cancelados</p>
              </template>
              <OrderTable
                id="canceled"
                resourceName="orderResourceList"
                :url="endpoints.findAll"
                role="CAN"
                movementType="SAI"
                ref="canceledTable"
              />
            </b-tab>
          </b-tabs>
        </div>
      </b-row>

    </div>
  </div>
</template>

<script>
  import { OrderTable}  from '@/components/tables'
  import { ModelListSelect } from 'vue-search-select'
  import { httpClient } from '@/service'
  import { LoadingAnimation } from '@/components/loaders'
  import shared from '@/shared/shared'

  export default {
    name: 'orders-prepare-all',
    components: { OrderTable, ModelListSelect, LoadingAnimation },

    data () {
      return {
        urlAllPrepare: '',
        urlAllComplete: '',
        endpoints: {
          findAll: process.env.VUE_APP_API_URL + 'orders'
        },
        filter: null,
        warehouses: [],
        ticketStatuses: [{id: null, label: 'Todos'}],
        routes: [{id: null, name: 'Todas'}],
        selectedWarehouse: {},
        selectedTicketStatus: {},
        selectedRoute: {},
        searchTicketStatusInProcess: false,
        searchRouteInProcess: false,
        ticketStatusSearchTimeout: {},
        routeSearchTimeout: {},
        loadingWarehouses: false,
        loadingTicketStatuses: false,
        loadingRoutes: false,
        selectedOperation: {},
        operations: [],
        sortOptions: [
          { value: 'asc', label: 'Ascendente', icon: 'sort-down' },
          { value: 'desc', label: 'Descendente', icon: 'sort-up' }
        ],
        selectedSortOption: {},
        items: [{id: null, label: 'Todos'}],
        itemSearchTimeout: {},
        searchItemInProcess: false,
        loadingItems: false,
        selectedItem: {},
        activeTabIndex: 0,
        isUserManager: false,
        authUser: false
      }
    },

    beforeMount() {
      this.operations = shared.getOperations().filter(o => o.value !== 'ENT');
      this.activeTabIndex = Number(shared.getLocalStorageString('SAI/activeTabIndex') || 0);
      this.filter = shared.getLocalStorageString('SAI/filterText');
      this.selectedSortOption = shared.getLocalStorageObj('order/selectedSortOption');

      if (!this.selectedSortOption || !this.selectedSortOption.value) {
        this.selectedSortOption = this.sortOptions[0];
      }

      this.setIsUserManager();
    },

    mounted : async function () {
      this.$store.commit('app/SET_CURRENT_PAGE', { title:'Separações', size: 3})
      /*const { allOrderOut, allOrderOutComplete } = localStorage.getItem('endpoints/order');
      this.urlAllPrepare = allOrderOut.href
      this.urlAllComplete = allOrderOutComplete.href*/
      this.getWarehouses();
      this.selectedWarehouse = shared.getLocalStorageObj('order/warehouse');
      this.selectedOperation = shared.getLocalStorageObj('order/SAI/selectedOperation');
      shared.setProfileAvailableOperations(this);

      this.selectedTicketStatus = shared.getLocalStorageObj('order/ticketStatus');
      if (this.selectedTicketStatus && this.selectedTicketStatus.id && Object.keys(this.selectedTicketStatus).length) {
        this.ticketStatuses.push(this.selectedTicketStatus);
        this.setTicketStatusLabel();
      }

      this.selectedItem = shared.getLocalStorageObj('order/filterItem');
      if (this.selectedItem && this.selectedItem.id && Object.keys(this.selectedItem).length) {
        this.items.push(this.selectedItem);
        this.setItemsLabel();
      }

      this.onFiltering();
    },

    beforeRouteLeave (to, from, next) {
      this.getTable().beforeRouteLeave();
      next(true);
    },

    methods: {
      getTable() {
        return this.$refs.releasedTable || this.$refs.inCheckTable || this.$refs.completeTable || this.$refs.canceledTable;
      },

      onFiltering() {
        shared.setLocalStorageString('SAI/filterText', this.filter);

        if (this.$refs.releasedTable)
          shared.setLocalStorageString('SAI/activeTabIndex', 0);

        else if (this.$refs.completeTable)
          shared.setLocalStorageString('SAI/activeTabIndex', 1);

        else if (this.$refs.canceledTable)
          shared.setLocalStorageString('SAI/activeTabIndex', 2);

        this.getTable().filter({
          filterText: this.filter,
          warehouse: this.selectedWarehouse,
          item: this.selectedItem,
          operation: this.selectedOperation,
          page: null,
          sort: this.selectedSortOption.value,
          ticketStatus: this.selectedTicketStatus,
          location: {},
          route: this.selectedRoute
        });
      },

      getWarehouses() {
        this.loadingWarehouses = true;
        httpClient.post(`${process.env.VUE_APP_API_URL}stock-locales/condition?page=${0}&size=${100}`, {
          conditions: [{
            field: 'active',
            value: true
          }]
        }).then(({ data }) => {
          this.loadingWarehouses = false;
          this.warehouses = data.data.content;
          this.warehouses.unshift({id: null, name: 'Todos'});
        })
        .catch((error) => {
          this.loadingWarehouses = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar os armazéns");
          }
        });
      },

      tabChanged() {
        this.$nextTick(() => {
          this.onFiltering();
        });
      },

      changeSortOption() {
        if (this.selectedSortOption.value === this.sortOptions[0].value) {
          this.selectedSortOption = this.sortOptions[1];
        } else {
          this.selectedSortOption = this.sortOptions[0];
        }

        shared.setLocalStorageObj('order/selectedSortOption', this.selectedSortOption);
        this.onFiltering();
      },

      async searchTicketStatus (searchText) {
        if (!this.searchTicketStatusInProcess && (
          (searchText && searchText.length) || !this.selectedTicketStatus || Object.keys(this.selectedTicketStatus).length == 0
        )) {
          if (this.ticketStatusSearchTimeout) {
            clearTimeout(this.ticketStatusSearchTimeout);
          }

          this.ticketStatusSearchTimeout = setTimeout(() => {
            this.searchTicketStatusInProcess = true

            if (searchText && searchText.length) {
              httpClient.post(`${process.env.VUE_APP_API_URL}ticket-statuses/condition?page=${0}&size=${10}&sort=description`, {
                conditions: [
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'description',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },{
                        field: 'situation',
                        conditionalOperator: 'EQUALS',
                        value: searchText
                      }
                    ]
                  }
                ]
              })
              .then(({ data }) => {
                this.searchTicketStatusInProcess = false
                this.ticketStatuses = data.data.content
                this.setTicketStatusLabel()
                this.ticketStatuses.unshift({id: null, label: 'Todos'});
              })
              .catch((error) => {
                this.searchTicketStatusInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            } else {
              httpClient.get(`${process.env.VUE_APP_API_URL}ticket-statuses/?page=${0}&size=${10}&sort=description`)
              .then(({ data }) => {
                this.searchTicketStatusInProcess = false
                this.ticketStatuses = data.data.content
                this.setTicketStatusLabel();

                this.ticketStatuses.unshift({id: null, label: 'Todos'});
              })
              .catch((error) => {
                this.searchTicketStatusInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            }
          }, 500);
        }
      },

      async searchRoute(searchText) {
        if (!this.searchRouteInProcess && (
          (searchText && searchText.length) || !this.selectedTicketStatus || Object.keys(this.selectedTicketStatus).length == 0
        )) {
          if (this.ticketStatusSearchTimeout) {
            clearTimeout(this.ticketStatusSearchTimeout);
          }

          this.ticketStatusSearchTimeout = setTimeout(() => {
            this.searchRouteInProcess = true

            if (searchText && searchText.length) {
              httpClient.post(`${process.env.VUE_APP_API_URL}routes/condition?page=${0}&size=${10}&sort=name`, {
                conditions: [
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'name',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },{
                        field: 'ref',
                        conditionalOperator: 'EQUALS',
                        value: searchText
                      }
                    ]
                  }
                ]
              })
              .then(({ data }) => {
                this.searchRouteInProcess = false
                this.routes = data.data.content
                this.routes.unshift({id: null, name: 'Todas'});
              })
              .catch((error) => {
                this.searchRouteInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            } else {
              httpClient.get(`${process.env.VUE_APP_API_URL}routes/?page=${0}&size=${10}&sort=name`)
              .then(({ data }) => {
                this.searchRouteInProcess = false
                this.routes = data.data.content
                this.routes.unshift({id: null, name: 'Todas'});
              })
              .catch((error) => {
                this.searchRouteInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            }
          }, 500);
        }
      },

      async searchItem (searchText) {
        if (!this.searchItemInProcess && (
          (searchText && searchText.length) || !this.selectedItem || Object.keys(this.selectedItem).length == 0
        )) {
          if (this.itemSearchTimeout) {
            clearTimeout(this.itemSearchTimeout);
          }

          this.itemSearchTimeout = setTimeout(() => {
            this.searchItemInProcess = true

            if (searchText && searchText.length) {
              httpClient
              .post(`${process.env.VUE_APP_API_URL}items/condition?page=${0}&size=${10}`, {
                conditions: [
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'description',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },
                      {
                        field: 'ref',
                        conditionalOperator: 'LIKE_END',
                        value: shared.mountReduzidoFilter(searchText)
                      },
                      {
                        field: 'sku',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      },
                      {
                        field: 'gtin',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      }
                    ]
                  }
                ]
              })
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel(searchText);
                this.items.unshift({id: null, label: 'Todos'});
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            } else {
              httpClient
              .get(`${process.env.VUE_APP_API_URL}items/?page=${0}&size=${10}`)
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel(searchText);
                this.items.unshift({id: null, label: 'Todos'});
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            }
          }, 500);
        }
      },

      handleData(data) {
        this.searchItemInProcess = false
        this.totalRows = data.data.totalElements
        const items = data.data.content

        return items
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      setItemsLabel(searchText = null) {
        if (this.items && this.items.length) {
          this.items.forEach(item => {
            if (item.id) {
              item.label = this.getItemReduzidoFromRef(item.ref) + " - " + item.description;
            }
            if(searchText){
              if(item.sku && (item.sku.indexOf(searchText) >= 0)){
                item.label += ' - Ref: ' + item.sku;
              }
              if(item.gtin && (item.gtin.indexOf(searchText) >= 0)){
                item.label += ' - Cód barras: ' + item.gtin;
              }
            }
          });
        }
      },

      setTicketStatusLabel() {
        if (this.ticketStatuses && this.ticketStatuses.length) {
          this.ticketStatuses.forEach(ticketStatus => {
            if (ticketStatus.id) {
              ticketStatus.label = ticketStatus.situation + " - " + ticketStatus.description;
            }
          });
        }
      },

      alwaysTrueFilterPredicate() {
        return true;
      },

      setIsUserManager() {
        this.authUser = shared.getLocalStorageObj('auth/user');

        if (this.authUser && this.authUser.profile && this.authUser.profile.modules) {
          let adjustmentsProfileModule = this.authUser.profile.modules.find(pm => {
            return pm.name && pm.name.toString().toUpperCase() == "ORDERS"
          });

          this.isUserManager = adjustmentsProfileModule && adjustmentsProfileModule.canExecute;
        }
      }
    },

    watch: {
      'selectedWarehouse': function(newVal, oldVal) {
        shared.setLocalStorageObj('order/warehouse', this.selectedWarehouse);

        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.onFiltering();
        }
      },
      'selectedOperation': function(newVal, oldVal) {
        shared.setLocalStorageObj('order/selectedOperation', this.selectedOperation);

        if (!shared.entitiesAreEqual(newVal, oldVal, 'value')) {
          this.onFiltering();
        }
      },
      'selectedItem': function(newVal, oldVal) {
        shared.setLocalStorageObj('order/filterItem', this.selectedItem);

        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.onFiltering();
        }
      },
      'selectedTicketStatus': function(newVal, oldVal) {
        shared.setLocalStorageObj('order/ticketStatus', this.selectedTicketStatus);

        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.onFiltering();
        }
      }
    }
  }
</script>

<style scoped>

</style>
